import React from "react";
import "../static/scss/error.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../components/Navbar/Navbar";
import { Link } from "react-router-dom";

class Error extends React.Component {
  static getInitialProps({ res, err }) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    return { statusCode };
  }

  render() {
    return (
      <div className="container-fluid d-flex flex-column justify-content-center">
        <Navbar transparent={true} />
        {this.props.statusCode == 404 ? (
          <Link className="nf-link" to="/">
            <div className="container d-flex flex-row nf-image" />
            <div className="container text-center nf-text">
              <h2 className="oops">OOPS!</h2>
              <h2 className="msg">Page Not Found</h2>
              <a>Click Here To Go Home</a>
            </div>
          </Link>
        ) : (
          <Link className="nf-link" to="/">
            <div className="container d-flex flex-row error-image" />
          </Link>
        )}
      </div>
    );
  }
}
export default Error;
