import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedin,
  faInstagram,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";

const HomeSocialLinks = () => {
  return (
    <div className="col-md-3 col-sm-12">
      <div className="position-relative h-100 social-continer">
        <div className="social-links position-absolute fixed-bottom w-75 text-justify">

          {/*<Link to="#">*/}
          {/*  <a>*/}
          {/*    <FontAwesomeIcon icon={faFacebookSquare} />*/}
          {/*  </a>*/}
          {/*</Link>*/}
          {/*<Link to="#">*/}
          {/*  <a>*/}
          {/*    <FontAwesomeIcon icon={faLinkedin} />*/}
          {/*  </a>*/}
          {/*</Link>*/}
          {/*<Link to="#">*/}
          {/*  <a>*/}
          {/*    <FontAwesomeIcon icon={faTwitterSquare} />*/}
          {/*  </a>*/}
          {/*</Link>*/}
          {/*<Link to="#">*/}
          {/*  <a>*/}
          {/*    <FontAwesomeIcon icon={faInstagram} />*/}
          {/*  </a>*/}
          {/*</Link>*/}
        </div>
      </div>
    </div>
  );
};

export default HomeSocialLinks;
