import React, { Component } from "react";
import FacultyProfile from "../components/FacultyProfile/FacultyProfile";
import MainLayout from "../layout/MainLayout";
import clientCredentials from "../credentials/client";
import firebase from "firebase/app";
import "firebase/firestore";
import "isomorphic-unfetch";

class Faculty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      faculty: [],
    };
  }

  componentDidMount() {
    (async () => {
      try {
        firebase.initializeApp(clientCredentials);
      } catch (error) {}

      let db = firebase.firestore();
      let result = await new Promise((resolve, reject) => {
        db.collection("faculty")
          .get()
          .then((snapShot) => {
            let data = [];
            snapShot.forEach((doc) => {
              data.push(doc.data());
            });
            resolve(data);
          })
          .catch((error) => {
            reject([]);
          });
      });
      this.setState({ faculty: result });
    })();
  }

  render() {
    return (
      <MainLayout title="Faculties">
        <div className="container-fluid mb-5">
          <div className="container wow zoomIn">
            <h1 className="head-title head-centre mt-5">
              TO GUIDE YOU INTO SUCCESS,
              <br />
              <span>YOU NEED THE BEST MINDS OUT THERE!</span>
            </h1>
            <p className="text-centre">
              Meet the faculties that help to find and nurture your skills that
              helps you to become a whole new individual, well adept at facing
              and solving tasks that come before you, enabling you to grow a bit
              each time.
            </p>
          </div>
          <FacultyProfile
            className="container-fluid"
            data={this.state.faculty}
          />
        </div>
      </MainLayout>
    );
  }
}

export default Faculty;
