import React from "react";
import { Link } from "react-router-dom";

const SpecialityCard = ({ data }) => {

    return (
        <div className="col-sm-4 mt-5 wow fadeInUp" key={data.id}>
            <div className="cource-card">
                <div className="cource-img">
                    <img src={data.image}  />
                </div>
                <div className="details text-center">
                    <h4>{data.title.toUpperCase()}</h4>

                    <div className="vertical-center p-2">
                        {data.content}

                    </div>
                    <div>
                        {data.link? (<a className='link-btn ' className={data.title +' link-btn'} href={data.link}>Read More</a>):null}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SpecialityCard;
