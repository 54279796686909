import React, { Component } from "react";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css";

class GalleryGrid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			photoIndex: 0,
			isOpen: false
		};
	}
	render() {
		const { image, width } = this.props;
		const { photoIndex, isOpen } = this.state;
		const style1 = { backgroundImage: "url(" + image[0].img + ")" };
		const style2 = { backgroundImage: "url(" + image[1].img + ")" };
		const style3 = { backgroundImage: "url(" + image[2].img + ")" };
		const style4 = { backgroundImage: "url(" + image[3].img + ")" };
		const style5 = { backgroundImage: "url(" + image[4].img + ")" };
		const style6 = { backgroundImage: "url(" + image[5].img + ")" };
		const style7 = { backgroundImage: "url(" + image[6].img + ")" };
		const style8 = { backgroundImage: "url(" + image[7].img + ")" };
		const style9 = { backgroundImage: "url(" + image[8].img + ")" };
		const style10 = { backgroundImage: "url(" + image[9].img + ")" };
		const style11 = { backgroundImage: "url(" + image[10].img + ")" };
		var maze = null;
		var list = null;
		var pos = 0;
		if (width > 991) {
			pos = 11;
			maze = (
				<div className="maze">
					<div className="row">
						<div className="col-md-5">
							<h1 className="head-title-gallery">
								HAVE A PEEK INTO OUR WORLD, GO THROUGH OUR
								<span> MILESTONES AND ACTIVITIES.</span>
							</h1>
						</div>
						<div className="col-md-7 row">
							<div
								className="col-md-6 img"
								style={style1}
								onClick={() => this.setState({ photoIndex: 0, isOpen: true })}
							/>
							<div
								className="col-md-6 img"
								style={style2}
								onClick={() => this.setState({ photoIndex: 1, isOpen: true })}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4 ml-auto">
							<div className="row">
								<p className="mb-5">
									We love to share our experiences, moments with you through this
									platform, trying to be an inch closer to each and everyone out there.
								</p>
								<div
									className="col-md-7 mt-5 img"
									style={style3}
									onClick={() => this.setState({ photoIndex: 2, isOpen: true })}
								/>
								<div
									className="col-md-5 img rt"
									style={style4}
									onClick={() => this.setState({ photoIndex: 3, isOpen: true })}
								/>
							</div>
						</div>

						<div className="col-md-8 row ml-auto">
							<div
								className="col-md-3 img lt"
								style={style5}
								onClick={() => this.setState({ photoIndex: 4, isOpen: true })}
							/>
							<div className="col-md-9 row">
								<div
									className="col-md-3 img"
									style={style6}
									onClick={() => this.setState({ photoIndex: 5, isOpen: true })}
								/>
								<div
									className="col-md-4 img"
									style={style7}
									onClick={() => this.setState({ photoIndex: 6, isOpen: true })}
								/>
								<div
									className="col-md-5 img"
									style={style8}
									onClick={() => this.setState({ photoIndex: 7, isOpen: true })}
								/>
								<div
									className="col-md-5 img"
									style={style9}
									onClick={() => this.setState({ photoIndex: 8, isOpen: true })}
								/>
								<div
									className="col-md-3 img"
									style={style10}
									onClick={() => this.setState({ photoIndex: 9, isOpen: true })}
								/>
								<div
									className="col-md-4 img"
									style={style11}
									onClick={() => this.setState({ photoIndex: 10, isOpen: true })}
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}
		const images = image.slice(pos);
		console.log(images.length);
		var rows;
		var rowTemplate = "";
		if (width > 991) {
			rows = images.length / 3.3 + 0.5;
			rows = Math.round(rows);
			for (var i = 1; i < rows; i++) {
				rowTemplate = rowTemplate + '"1fr 1fr 1fr 1fr 1fr" 33.33vh ';
			}
		} else if (width > 768) {
			rows = images.length / 2.1;
			rows = Math.round(rows);
			for (var i = 1; i < rows; i++) {
				rowTemplate = rowTemplate + '"1fr 1fr 1fr" 250px ';
			}
		} else {
			rows = images.length / 1.4;
			rows = Math.round(rows);
			for (var i = 1; i < rows; i++) {
				rowTemplate = rowTemplate + '"1fr 1fr" 200px';
			}
		}
		console.log(rows);

		const styleRow = { gridTemplate: rowTemplate };
		var listItems = null;
		listItems = images.map((item, i) => {
			var cls = i % 10;
			var clsAlp = "box wow zoomIn ";
			switch (cls) {
				case 0:
					clsAlp += "a";
					break;
				case 1:
					clsAlp += "b";
					break;
				case 2:
					clsAlp += "c";
					break;
				case 3:
					clsAlp += "d";
					break;
				case 4:
					clsAlp += "e";
					break;
				case 5:
					clsAlp += "f";
					break;
				case 6:
					clsAlp += "g";
					break;
				case 7:
					clsAlp += "h";
					break;
				case 8:
					clsAlp += "i";
					break;
				default:
					clsAlp += "j";
					break;
			}
			var key = i + pos;
			return (
				<div
					key={key}
					className={clsAlp}
					onClick={() => this.setState({ photoIndex: key, isOpen: true })}
				>
					<img src={item.img} alt={item.alt} />
				</div>
			);
		});
		list = (
			<div className="container-gal" style={styleRow}>
				{listItems}
			</div>
		);

		return (
			<div className="container-gallery my-5 wow zoomIn">
				<h1 className="text-center head-title-gallery bg-step m-4">
					THE GREAT HOPE OF SOCIETY IS<span> INDIVIDUAL CHARECTOR. </span>
				</h1>
				{maze}
				{list}
				{isOpen && (
					<Lightbox
						mainSrc={image[photoIndex].img}
						nextSrc={image[(photoIndex + 1) % image.length]}
						prevSrc={image[(photoIndex + image.length - 1) % image.length]}
						onCloseRequest={() => this.setState({ isOpen: false })}
						onMovePrevRequest={() =>
							this.setState({
								photoIndex: (photoIndex + image.length - 1) % image.length
							})
						}
						onMoveNextRequest={() =>
							this.setState({
								photoIndex: (photoIndex + 1) % image.length
							})
						}
					/>
				)}
			</div>
		);
	}
}
export default GalleryGrid;
