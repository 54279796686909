import React, { Component } from "react";
import StarRatings from 'react-star-ratings';

class FacultyItem extends Component {
    render() {
        const {data}=this.props
        return (
            <div>
                <div className="card">
                    <img className="card-img-top" src={data.image} alt="Card image" />
                    <div className="card-body">
                        <h4 className="name-title">{data.name}</h4>
                        <p className="faculty-grey">Specialised In</p>
                        <p>{data.specialisation}</p>
                        <p className="faculty-grey">Experience</p>
                        <StarRatings
                            rating={data.experience}
                            starRatedColor='rgb(240, 121, 36)'
                            numberOfStars={5}
                            name='rating'
                            starDimension="20px"
                        />
                        <p className="faculty-grey mt-2">Description</p>
                        <p>{data.desc}</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default FacultyItem;