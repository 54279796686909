import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

class HomeCaroselControll extends Component {
  responsive = {
    0: { items: 3 },
    720: { items: 3 },
    1024: { items: 4 },
  };

  onSlideChange = () => {
    // this.Carousel;
  };

  render() {
    const { content, onChanged } = this.props;

    const images = content.map((item) => {
      return (
        <img
          key={item.id}
          className="carousel-item carousel-img"
          src={item.image}
          alt=""
        />
      );
    });

    return (
      <div className="col-md-9 col-sm-12">
        <div className="container pr-0">
          <div className="row">
            <div className="col-sm-3 controlls">
              <div className="position-relative h-100">
                <div className="position-absolute fixed-bottom">
                  <img
                    className="prev-buttom"
                    src="/static/images/prev-buttom.svg"
                    onClick={() => this.Carousel.slidePrev()}
                  />
                  <img
                    className="next-buttom"
                    src="/static/images/next-buttom.svg"
                    onClick={() => this.Carousel.slideNext()}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-9 col-sm-12 pr-0">
              <div className="ribbon" />
              <AliceCarousel
                mouseDragEnabled={true}
                responsive={this.responsive}
                autoPlay={true}
                buttonsDisabled={true}
                dotsDisabled={true}
                autoPlayInterval={6000}
                onSlideChanged={onChanged}
                onSlideChange={this.onSlideChange}
                ref={(el) => (this.Carousel = el)}
              >
                {images}
              </AliceCarousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeCaroselControll;
