import React from "react";
import smallLogo from "../../static/images/logo-small.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedin,
  faInstagram,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhoneAlt, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const FooterBrand = () => {
  return (
    <div className="col-sm-4 branding">
      <div className="logo-footer">
        <img className="float-left w-75 p-1" src={smallLogo} />
      </div>
      <div className="address p-1">
        <p className="text-left">
          MnM Academy LLP
          <br /> LLPIN: AAQ-4468<br/>
          Calicut, Kerala, India
        </p>
        <p className="text-left">info@masternmasters.com </p>
        <p className="text-left"> +91 8921036048, +91 9747622922</p>
      </div>

     {/* <div className="social-links w-75 text-justify">
        <Link to="#">
          <FontAwesomeIcon icon={faFacebookSquare} />
        </Link>
        <Link to="#">
          <FontAwesomeIcon icon={faLinkedin} />
        </Link>
        <Link to="#">
          <FontAwesomeIcon icon={faTwitterSquare} />
        </Link>
        <Link to="#">
          <FontAwesomeIcon icon={faInstagram} />
        </Link>
      </div>*/}
    </div>
  );
};

export default FooterBrand;
