import React from "react";
import MainLayout from "../layout/MainLayout";
import CourceCard from "../components/CourceCard/CourceCard";
import clientCredentials from "../credentials/client";
import firebase from "firebase/app";
import "firebase/firestore";
import "isomorphic-unfetch";

class Courses extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cources: [] };
  }

  componentDidMount() {
    (async () => {
      try {
        firebase.initializeApp(clientCredentials);
      } catch (error) {}

      let db = firebase.firestore();
      let result = await new Promise((resolve, reject) => {
        db.collection("courses")
          .get()
          .then((snapShot) => {
            let data = [];
            snapShot.forEach((doc) => {
              data.push(doc.data());
            });
            resolve(data);
          })
          .catch((error) => {
            reject([]);
          });
      });
      this.setState({ cources: result });
    })();
  }

  render() {
    const { cources } = this.state;

    console.log("cources", cources);

    const cards = cources.map((card) => {
      return <CourceCard data={card} key={card.id} />;
    });

    return (
      <MainLayout title="Courses">
        <div className="container mb-5">
          <div className="content wow zoomIn">
            <h1 className="head-title head-centre mt-5 mb-5">
              THE GREATEST ASSET OF AN INDIVIDUAL IS <br />
              <span>THE CHARACTER.</span>
            </h1>
            <p className="text-center">
              Choose from a wide range of courses created by deep and
              intelligent analysis of requirements for different age groups,
              prepared by experts to determine the necessary traits for the
              development of each individual, making sure they are ready to
              tackle the challenges ahead in their life with confidence. Courses
              are carefully crafted to equip individuals with best skills and
              tools to enhance their education/career.
            </p>
          </div>
        </div>
        <div className="container mb-5">
          <div className="row">{cards}</div>
        </div>
      </MainLayout>
    );
  }
}

export default Courses;
