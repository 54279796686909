import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Booking from "./pages/Booking";
import CourseDetail from "./pages/CourseDetail";
import Courses from "./pages/Courses";
import Error from "./pages/Error";
import Faculty from "./pages/Faculty";
import Gallery from "./pages/Gallery";
import GetInTouch from "./pages/GetInTouch";
import Home from "./pages/Home";
import MasterTest from "./pages/MasterTest";
import About from "./pages/About";
import Specialities from "./pages/Specialities";
import EventRegistration from "./pages/EventRegistration";
import Projects from "./pages/Projects";
import Mastersmeet from "./pages/Mastersmeet";
import Skillme from "./pages/Skillme";
import Eclub from "./pages/Eclub";
import CertifiedCourses from "./pages/CertifiedCourses";
import UploadWork from "./pages/UploadWork";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/courses" component={Courses} />
        <Route
          path="/coursedetail/:id"
          render={(props) => <CourseDetail {...props} />}
        />
        <Route path="/about" component={About} />
        <Route path="/faculty" component={Faculty} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/contact" component={GetInTouch} />
        <Route path="/specialities" component={Specialities} />
        <Route path="/book-counseling" component={Booking} />
        <Route path="/master-test" component={MasterTest} />
        {/* <Route path="/competition" component={EventRegistration} /> */}
        <Route path="/projects" component={Projects} />
        <Route path="/mastersmeet" component={Mastersmeet} />
        <Route path="/skillme" component={Skillme} />
        <Route path="/eclub" component={Eclub} />
        <Route path="/certified" component={CertifiedCourses} />
        <Route path="/upload" component={UploadWork} />
        <Route path="*" component={Error} />

      </Switch>
    </BrowserRouter>
  );
};

export default Router;
