import React from "react";
import FooterBrand from "./FooterBrand";
import FooterMenu from "./FooterMenu";
import FooterNewsletter from "./FooterNewsletter";

const Footer = () => {
  return (
    <footer className="px-5">
      <div className="container-fluid">
        <div className="row">
          <FooterBrand />
          <FooterMenu />
          <FooterNewsletter />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
