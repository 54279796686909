import React, {Component} from "react";
import MainLayout from "../layout/MainLayout";
import fetch from "isomorphic-unfetch";

class About extends Component {


    render() {
        return (
            <MainLayout title="Contact Us">
                <div className="container mb-5">
                    <div className="content wow zoomIn">
                        <h1 className="head-title head-centre mt-5 mb-5">
                            <span>ABOUT US</span>
                        </h1>
                        <p className="text-center">
                            Master n Masters is the brainchild of a series of original and path breaking experiments
                            spearheaded by a group of socially committed educationists specialised in child psychology,
                            neuro-linguistic programming, educational psychology, and innovative technology. The
                            organisation aims at building a holistic system focused on the intellectual development of
                            individuals. Utilising state-of-the-art technology, edutainment technique, and intensive
                            research, we strive to impart creative and innovative ideas and services to help individuals
                            discover their innate skills and capabilities and to realise their full potential.
                        </p>

                    </div>
                    <div className="content wow zoomIn">
                        <h1 className="head-title head-centre mt-5 mb-5">
                            <span>Our Growth Over the Years</span>
                        </h1>
                        <p className="text-center">
                            Master n Masters has been guiding both children as well as adults to explore their inner
                            selves and identify their strengths and limitations to work on it. Since our inception in
                            2010, we have been dedicated to provide services that had social values to empower people.
                            Our efficient team has mastered the art of skill development in various fields and is now
                            helping people around the world to discover their best selves. <br/> The deep and comprehensive
                            research we conduct and the commitment with which we take up every single student or
                            customer is what makes us stand out. We blend innovation and technology in the right amount
                            to create our services and projects.
                        </p>

                    </div>

                </div>
            </MainLayout>
        );
    }
}

export default About;
