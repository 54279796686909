import React, { Component } from "react";
import master from "../../static/images/master.svg";

export class TypingIndicator extends Component {
	render() {
		return (
			<div className="chat-item">
				<div className="chat-avatar">
					<img src={master} alt="Master" />
				</div>
				<div className="chat-content">
					<div className="typing-indicator">
						<span />
						<span />
						<span />
					</div>
				</div>
			</div>
		);
	}
}

export default TypingIndicator;
