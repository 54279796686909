import React from "react";
import MainLayout from "../layout/MainLayout";
import SpecialityCard from "../components/Specialities/specialityCard";
import clientCredentials from "../credentials/client";
import firebase from "firebase/app";
import "firebase/firestore";
import "isomorphic-unfetch";

class Specialities extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cources: [] };
    }

    componentDidMount() {
        (async () => {
            try {
                firebase.initializeApp(clientCredentials);
            } catch (error) {}

            let db = firebase.firestore();
            let result = await new Promise((resolve, reject) => {
                db.collection("courses")
                    .get()
                    .then((snapShot) => {
                        let data = [];
                        snapShot.forEach((doc) => {
                            data.push(doc.data());
                        });
                        resolve(data);
                    })
                    .catch((error) => {
                        reject([]);
                    });
            });
            this.setState({ cources: result });
        })();
    }


    render() {
        const  cources  = getSpecialities()

        console.log("cources", cources);

        const cards = cources.map((card) => {
            return <SpecialityCard data={card} key={card.id} />;
        });

        return (
            <MainLayout title="Courses">
                <div className="container ">
                    <div className="content wow zoomIn">
                        <h1 className="head-title head-centre pro-title">
                             <br />
                            <span>Focal Areas</span>
                        </h1>

                    </div>
                </div>
                <div className="container ">
                    <div className="row">{cards}</div>
                </div>
            </MainLayout>
        );


    }

}
const getSpecialities=()=>{
    const list=[
        {
            id:1,
            title:"Child Development",
            content:"To realise a child’s true potential, we follow a systematic conception of child development techniques.",
            image:"/static/images/specialities/child.jpg"
        },
        {
            id:2,
            title:"Psychometric Assessment",
            content:"Master n Masters, employ psychometric tests to understand and analyse the cognitive levels of each individual, helping them organise and polish their skills.",
            image:"/static/images/specialities/psychometric.jpg"
        },
        {
            id:3,
            title:"Mentoring Services",
            content:"We provide customised support service to sharpen your skills and to fulfill your goals.",
            image:"/static/images/specialities/mentoring.jpg"
        },
        {
            id:4,
            title:"Counselling",
            content:"We offer emotional, psychological, adolescent, parental and career counselling services following international standards and professional guidelines.",
            image:"/static/images/specialities/counselling.jpg"
        },
        {
            id:5,
            title:"Innovative Technology",
            content:"The love for humanity and nature profoundly influences our digital philosophy. We try to blend these three aspects in perfect harmony.",
            image:"/static/images/specialities/innovative.jpg"
        },
        {
            id:6,
            title:"R&D",
            content:"Our research wing works extensively to come up with curated courses that fuse psychological knowledge with the latest innovations in science and technology.",
            image:"/static/images/specialities/rnd.jpg"
        },
        {
            id:7,
            title:"Certified Courses ",
            content:"We are equipped to provide certified courses and programs with rigorous skill training to develop oneself into a master in their area of expertise.",
            image:"/static/images/specialities/certified.jpg"
        },
        {
            id:7,
            title:"OUTREACH PROGRAMMES ",
            content:"We associate with institutions and organisations to create shared platforms for a common cause through our innovative programs.",
            image:"/static/images/specialities/collaboration.jpg"
        },


    ]
    return list
}

export default Specialities;
