import React, {Component} from "react";
import MainLayout from "../layout/MainLayout";
import fetch from "isomorphic-unfetch";

class Mastersmeet extends Component {


    render() {
        return (
            <MainLayout title="Contact Us">
                <div className="container mb-5">
                    <div className="content wow zoomIn">
                        <h1 className="head-title head-centre mt-5 mb-5">
                            <span>MASTERSMEET</span>
                        </h1>
                        <h5 className="sub-title head-centre mb-5">
                            <span>Accentuate Your Online Communications</span>
                        </h5>
                        <p className="text-center height-2">
                            MastersMeet is a virtual meeting platform powered by AI and VR technologies, merging the
                            features of personal and group online communication, online classroom solutions, web talk,
                            webinar podiums and social media platforms into one neat application. Offering a plethora of
                            features ranging from the facility to conduct tests and examinations to polls and continuous
                            evaluation, the app will be a perfect tool for all organizational needs. The app also offers
                            top of the class security for online meetings displacing any fears of data vulnerability. In
                            the case of educational institutions, the teacher/instructor can watch, test and examine the
                            students’ progress from time to time. Continuous evaluation, teacher-student interaction,
                            parental control etc. are embedded in the application. The Hub to Hub training is a special
                            feature that makes use of virtual reality to provide a real-time classroom interaction
                            experience for the users.
                        </p>
                        <h3 className='sub-title'><span>Features of Mastersmeet App</span></h3>
                        <ul className='height-2 '>
                            <li>Comprehensive virtual meeting platform</li>
                            <li>Benefits both educational institutions as well as organizations</li>
                            <li>Multiple features</li>
                            <li>Integrated social media platforms</li>
                            <li>Online polls and tests</li>
                            <li>Data Security</li>
                        </ul>

                    </div>


                </div>
            </MainLayout>
        );
    }
}

export default Mastersmeet;
