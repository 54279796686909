import React, {Component} from "react";
import MainLayout from "../layout/MainLayout";
import fetch from "isomorphic-unfetch";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import SweetAlert from 'react-bootstrap-sweetalert';
class UploadWork extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        /*this.addCountries=this.addCountries.bind(this)*/
        this.state = {
            alert: null
        };

    }
    successAlert() {
        const getAlert = () => (
            <SweetAlert
                success
                title="Success!"
                onConfirm={() => this.hideAlert()}
            >
                Your contest is successfully uploaded
            </SweetAlert>
        );
        console.log("done")
        this.setState({
            alert: getAlert()
        });
    }

    hideAlert() {
        console.log('Hiding alert...');
        this.setState({
            alert: null
        });
    }
    async handleSubmit(event) {
        event.preventDefault();
        var reader = new FileReader();
        var file = document.getElementById('attach').files[0];
        console.log(file)
        reader.onload = ()=>{
            console.log("Reader load")
            document.getElementById('fileContent').value=reader.result;
            document.getElementById('filename').value=file.name;
            const data = new FormData(document.getElementById('uploadForm'));

            document.getElementById('sub_btn').innerText = 'Submit...'
            fetch('https://script.google.com/macros/s/AKfycbxssxk0mtx1tpk80Sp0YSZYvbokjH07z0U-VHlmZcelZSpmX-FO/exec', {
                method: 'POST',
                body: data,
            }).then((response) => {
                document.getElementById('sub_btn').innerText = 'Submit'
                //ToastsStore.success("Thanks for your registration on National Art Competition 2020... The registration link will be provided in this website shortly. www.masternmasters.com")

                viewAlert()
                document.getElementById('uploadForm').reset()

            })

        }
        const viewAlert=()=>{
            console.log("view alert")
            this.successAlert()
        }
        reader.readAsDataURL(file);


    }

    ///handle field change

    viewImg(event){
        console.log("img view")
        console.log(event)
        document.getElementById('imgView').src=URL.createObjectURL(event.target.files[0])
    }

    render() {

        return (
            <MainLayout title="Contact Us">

                    {this.state.alert}


                <div className="container-fluid mb-5">

                    <div className="row">
                        <div className="art-section pt-5 container ">

                            <h2 className='text-center'>Upload Your File</h2>
                            <form action="" className='mt-4' id='uploadForm' onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="">Name</label>
                                        <input type="text" name="name" id="" className='form-control' required/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="">Mobile</label>


                                        <input type="tel" pattern=".{10,}" name="mobile" id="" className='form-control' required
                                               title="Enter valid mobile number "/>
                                        <small>Please enter mobile number with country code</small>

                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <label htmlFor="">Email</label>
                                        <input type="email" name="email" id="" className='form-control' required/>
                                    </div>
                                    <div className="col-md-6 ">
                                        <label htmlFor="">Upload Your Picture(jpg,png and jpeg)</label>
                                        <div className="input-group">

                                            <div className="custom-file">

                                                <input type="file" className="custom-file-input" id="attach" name='attach'
                                                       aria-describedby="inputGroupFileAddon01" onChange={this.viewImg} />
                                                    <label className="custom-file-label" htmlFor="inputGroupFile01">Choose
                                                        file</label>
                                                <input type="hidden" value="" name="fileContent" id="fileContent" />
                                                    <input type="hidden" value="" name="filename" id="filename" />

                                            </div>
                                        </div>
                                        <img src="" alt="" id="imgView" className="img-fluid"/>

                                    </div>

                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-6 ">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-check">


                                                </div>
                                            </div>

                                            <div className="col-md-12 mt-3">

                                                <div className="row">



                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center pt-4">
                                        <button className='btn btn-success submit-btn' id='sub_btn'>Submit</button>
                                        <ToastsContainer position={ToastsContainerPosition.BOTTOM_CENTER}
                                                         store={ToastsStore}/>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>


                </div>
            </MainLayout>
        );
    }


}

export default UploadWork;
