import React, {Component} from "react";
import MainLayout from "../layout/MainLayout";
import fetch from "isomorphic-unfetch";

class CertifiedCourses extends Component {


    render() {
        return (
            <MainLayout title="Contact Us">
                <div className="container mb-5">
                    <div className="content wow zoomIn">
                        <h1 className="head-title head-centre mt-5 mb-3">
                            <span>CERTIFIED COURSES</span>
                        </h1>
                        <p className="text-center">
                            Master n Masters provide certified courses and programs that will help nourish the
                            personalities to equip them pursue goals confidently at ease. We help you build your path to
                            future through our carefully guided courses.
                        </p>

                    </div>
                    <div className="content wow zoomIn certified">
                        <h4 className='sub-title mt-5'>Star Skill Masters</h4>
                        <p>STAR SKILL MASTERS, specifically designed for students of age <strong>9 to
                            12,</strong> explores the potential
                            of a child to define the complex demands of self and society in the highly dynamic
                            environment through our edutainment program. The five-level program will analytically
                            evaluate the child in various tasks and activities during the course of the programme. These
                            collaborative programmes with parents have the potential to provide new insights to parents
                            on the child’s abilities and challenges. We also provide continuous orientation programmes
                            to parents to help develop a scientific culture in nurturing children.
                        </p>
                        <h4 className='sub-title mt-5'>Teen Skill Masters
                        </h4>
                        <p>Teenagers are expected to attain cognitive and non-cognitive development which equips them to
                            have a better understanding of their self and the world around. The interwoven teen centred
                            activities and edutainment programs of TEEN SKILL MASTERS are designed to unleash the latent
                            skills of teens to help them explore a vibrant world. The three-level program is
                            specifically designed for students of age <strong>13 to 19.</strong> The attitude of the
                            parents towards the
                            children during adolescent period is a determining factor in their development. Active
                            parental cooperation and full-time participation in the program is demanded. Parent
                            participation in the TEEN SKILL MASTERS provides opportunities to the parents to have a
                            better understanding of their child. The counselling given to the parents as part of the
                            program enhances their insights to become a responsible guardian.</p>
                        <h3 className='sub-title mt-5'>Professional Skill Masters</h3>
                        <p>To carry out the work tasks effectively and excel in any profession, one should have
                            effective communication skills, decision making, problem-solving, propensity for teamwork,
                            team management, competence etc. Further, specific job-oriented skills, IT skills,
                            innovative talents and etiquettes are essentials to meet the challenges in goal-oriented
                            work culture. PROFESSIONAL SKILL MASTERS prepares young professionals to sharpen their
                            skills to excel in their career. The program is specifically designed for career hunters and
                            working professionals in various reputed firms, organizations etc. The three-level program
                            designed by experts provides specialized training to develop their soft skills and hard
                            skills to attain success both in personal and professional life.
                        </p>
                    </div>

                </div>
            </MainLayout>
        );
    }
}

export default CertifiedCourses;
