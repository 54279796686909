import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import FacultyItem from "./FacultyItem";

class FacultyProfile extends React.Component {
  render() {
    const responsive = {
      0: { items: 1 },
      720: { items: 2 },
      1024: { items: 4 },
    };

    const facutyItem = this.props.data.map((card) => {
      return <FacultyItem data={card} key={card.id} />;
    });

    return (
      <div className="faculty-body">
        <div className="row faculty-container mx-auto wow fadeInUp">
          <div
            className="btnPrev col-1"
            onClick={() => this.Carousel.slidePrev()}
          />
          <div className="col-10">
            <AliceCarousel
              mouseDragEnabled
              responsive={responsive}
              autoPlay={true}
              fadeOutAnimation={true}
              stagePadding={this.stagePadding}
              buttonsDisabled={true}
              dotsDisabled={false}
              duration={2000}
              ref={(el) => (this.Carousel = el)}
            >
              {facutyItem}
            </AliceCarousel>
          </div>
          <div
            className="btnNext col-1"
            onClick={() => this.Carousel.slideNext()}
          />
        </div>
      </div>
    );
  }
}

export default FacultyProfile;
