import React, { Component } from "react";
import MainLayout from "../layout/MainLayout";

class CourseDetail extends Component {
  render() {
    const courseObj = JSON.parse(this.props.location.courseData || "");

    console.log("cource", courseObj);

    let content;

    if (courseObj) {
      if (Array.isArray(courseObj.levels)) {
        const list = courseObj.levels.map((level) => (
          <a key={level}>{level}</a>
        ));
        content = (
          <>
            <h4 className="text-center coursedetail-t4">Levels</h4>
            <div className="links text-center">{list}</div>
          </>
        );
      } else {
        content = <h3 className="links text-center">{courseObj.levels}</h3>;
      }
    }

    return (
      <MainLayout title={courseObj?.courceName}>
        <div className="container-fluid mb-5 cnt-coursedetail">
          <img className="img-coursedetail" src={courseObj?.image} alt="" />
          <div className="container">
            <h1 className="head-title head-centre mt-5">
              <span>{courseObj?.courceName}</span>
            </h1>
            <h3 className="text-center">
              {courseObj?.catogory === ""
                ? ""
                : "( " + courseObj?.catogory + " )"}
            </h3>
            {content || ""}
            <p>
              Created for students from 3rd to 7th grade, to explore their
              potential and capability through an edutainment program. Divided
              into different levels, each with their own identification methods,
              assessment tools and bonus elements. Specific training modules and
              activities in each stage enhance the development of the student
              making sure they grab track of their skillset.
            </p>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default CourseDetail;
