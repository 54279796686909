import React from "react";

const HomeCarouselPreview = ({ image, pos, size, changed }) => {
  return (
    <div className="col-md-6 pr-0 col-sm-12">
      <div
        className={"preview-img " + (changed ? "animated zoomIn fast" : "")}
        style={{ backgroundImage: "url(" + image + ")", color: "white" }}
      >
        <div className="carousal-pos">
          <h1>
            {pos}
            <span> / {size}</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default HomeCarouselPreview;
