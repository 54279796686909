import React, {Component} from "react";
import MainLayout from "../layout/MainLayout";
import fetch from "isomorphic-unfetch";

class Eclub extends Component {


    render() {
        return (
            <MainLayout title="Contact Us">
                <div className="container mb-5">
                    <div className="content wow zoomIn">
                        <h1 className="head-title head-centre mt-5 mb-5">
                            <span>E-CLUB</span>
                        </h1>
                        <h5 className="sub-title head-centre mb-5">
                            <span>Education From A Different Perspective</span>
                        </h5>
                        <p className="text-center height-2">
                            To mold each member into resource personnel by tapping their advanced skills, experience and
                            knowledge through the unique platform of Edutainment E-Club. We focus on three major areas:
                            Enhancing Academic Performance,Enriching Hard Skills and Encouraging Soft Skills. We offer
                            quality guidance and supervision with individual attention and one on one discussions. The
                            number of candidates within a group is kept at a manageable minimum in order to ensure
                            effective communication between the mentors and the members.
                        </p>
                        <h3 className='sub-title'><span>Advantages of E-Club</span></h3>
                        <ul className='height-2 '>
                            <li>Exclusive and inimitable syllabus to convert leisure time into a quality time of joy and
                                learning, a two-way process of knowledge and education.
                            </li>
                            <li>Only a limited number of members in each group with clear demarcation on the basis of
                                age, location and language of communication.
                            </li>
                            <li>E-club is designed, organized, structured, executed and developed by club members with
                                the direction from Mentors.
                            </li>
                            <li>Help build-essential interpersonal skills, communication competence, confidence and
                                other hard and soft skills.
                            </li>
                            <li>Enhance academic performance and personality..</li>
                        </ul>

                    </div>
                    <div className="content wow zoomIn">

                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card club-membership">
                                <h5 className="card-header">Basic Plan</h5>
                                <div className="card-body">
                                    <h5 className="card-title">One Month (Online)
                                    </h5>
                                    <p className="card-text">Basic membership is offered free of cost as part of our
                                        social commitment. The curriculum imparts insights about leadership,
                                        communication, group dynamics and presentation skills.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card club-membership">
                                <h5 className="card-header">Premium Plan</h5>
                                <div className="card-body">
                                    <h5 className="card-title">3 Months (Online)
                                    </h5>
                                    <p className="card-text">Our premium membership offers a structured platform
                                        designed to enhance academic performance. The programme also focuses on boosting
                                        essential skills for personal and professional life.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card club-membership">
                                <h5 className="card-header">Silver Plan</h5>
                                <div className="card-body">
                                    <h5 className="card-title">4 Months (Online & Offline)

                                    </h5>
                                    <p className="card-text">In the Silver membership programme, we identify the passion & interest of each student by using various tools. On the basis of this assessment, we segregate students to different pools to develop a specific set of Hard & Soft Skills.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card club-membership">
                                <h5 className="card-header">Golden Plan</h5>
                                <div className="card-body">
                                    <h5 className="card-title">4 Months (Online & Offline)
                                    </h5>
                                    <p className="card-text">The Golden membership programme is envisaged as a project-based approach, built to be self-sufficient for the members. The programme provides part time earning programs for the students during their schooling, with real-time live experience.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </MainLayout>
        );
    }
}

export default Eclub;
