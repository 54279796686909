import React, { Component } from "react";
import user from "../../static/images/user.svg";

export class AnswerItem extends Component {
	render() {
		const { option, answer } = this.props;
		return (
			<div className="container-fluid chat-item answer">
				<div className="chat-content">
					<div className="answer-content">
						{option ? option + " . " : ""}
						{answer}
					</div>
				</div>
				<div className="chat-avatar">
					<img src={user} alt="User" />
				</div>
			</div>
		);
	}
}

export default AnswerItem;
