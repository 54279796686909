import React, { Component } from "react";
import "animate.css/animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../static/scss/master.scss";
import { initGA, logPageView } from "../utils/analytics";
import TestPageNavBar from "../components/TestPageNavbar/TestPageNavBar";
import WOW from "wowjs";

export class TestLayout extends Component {
  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
    if (typeof window !== "undefined") {
      window.WOW = WOW;
    }
    new WOW.WOW().init();
  }

  render() {
    return (
      <div className="full-screen color-darken">
        <TestPageNavBar endMethod={this.props.endMethod} />
        {this.props.children}
      </div>
    );
  }
}

export default TestLayout;
