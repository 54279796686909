import React, { Component } from "react";
import MainLayout from "../layout/MainLayout";
import fetch from "isomorphic-unfetch";

class GetInTouch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile: "",
      message: "",
      error: "",
      success: false,
      isLoading: false,
    };
  }
  ///handle field change
  onChangeHadler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: "",
    });
  };

  validateData() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.name.trim() === "") {
      this.setState({ error: "Please input your name" });
    } else if (this.state.email.trim() === "") {
      this.setState({ error: "Please input your email" });
    } else if (!re.test(this.state.email.trim().toLowerCase())) {
      this.setState({ error: "Please input a valid email" });
    } else if (this.state.mobile.trim() === "") {
      this.setState({ error: "Please input your mobile number" });
    } else if (!/^\d+$/.test(this.state.mobile.trim())) {
      this.setState({ error: "Please input a valid mobile number" });
    } else if (this.state.message.trim() === "") {
      this.setState({ error: "Please input your message" });
    } else {
      this.setState({ isLoading: true });
      this.sendToServer();
    }
  }

  async sendToServer() {
    let response = await new Promise((resolve, reject) => {
      let res = fetch("http://masternmasters.com/api/contact-us", {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "same-origin",
        body: JSON.stringify(this.state),
      })
        .then((r) => r.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
        //  console.log(error);
          resolve(true)
         // reject({ success: false });
        });
    });
    if (response.success) {
      this.setState({
        success: response.success,
        isLoading: false,
      });
    } else {
      this.setState({
        success: "Oopz.. Something went wrong..!!", //Reset to error
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <MainLayout title="Contact Us">
        <div className="container mb-5">
          <div className="content wow zoomIn">
            <h1 className="head-title head-centre mt-5 mb-5">
              GET <span>IN TOUCH</span>
            </h1>
            <p className="text-center">
              Please feel free to ask your queries and share with us your feedback and suggestions.
              We will get back to you soon.
            </p>
            {this.state.success ? (
              <div className="form-group">
                <p className="success">
                  Your info sent succesfully...
                  <br />
                  Our executive will get back to you soon...
                </p>
              </div>
            ) : (
              <form className="mt-5 mx-5 wow slideInUp">
                <div className="form-group">
                  <input
                    type="text"
                    required="required"
                    name="name"
                    onChange={this.onChangeHadler}
                  />
                  <label htmlFor="input" className="control-label">
                    Name
                  </label>
                  <i className="bar" />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    required="required"
                    name="email"
                    onChange={this.onChangeHadler}
                  />
                  <label htmlFor="input" className="control-label">
                    Email
                  </label>
                  <i className="bar" />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    required="required"
                    name="mobile"
                    onChange={this.onChangeHadler}
                  />
                  <label htmlFor="input" className="control-label">
                    Mobile Number
                  </label>
                  <i className="bar" />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    required="required"
                    name="message"
                    onChange={this.onChangeHadler}
                  />
                  <label htmlFor="input" className="control-label">
                    Message
                  </label>
                  <i className="bar" />
                </div>
                {this.state.error.length > 0 ? (
                  <div className="form-group">
                    <p className="error">{this.state.error}</p>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group">
                  <button
                    type="button"
                    className="button"
                    onClick={() => {
                      this.validateData();
                    }}
                  >
                    <span>
                      {this.state.isLoading ? "Loading..." : "Submit"}
                    </span>
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default GetInTouch;
