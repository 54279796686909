import React, { Component } from "react";

export class OptionItem extends Component {
	render() {
		const { option, answer, pos, selectUpdate, selected } = this.props;
		return (
			<div
				className={"option" + (selected ? " selected" : "")}
				onClick={() => {
					selectUpdate(option,answer,pos);
				}}
			>
				{option}. {answer}
			</div>
		);
	}
}

export default OptionItem;
