import React from "react";
import MainLayout from "../layout/MainLayout";
import SpecialityCard from "../components/Specialities/specialityCard";
import clientCredentials from "../credentials/client";
import firebase from "firebase/app";
import "firebase/firestore";
import "isomorphic-unfetch";

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cources: [] };
    }

    componentDidMount() {
        (async () => {
            try {
                firebase.initializeApp(clientCredentials);
            } catch (error) {}

            let db = firebase.firestore();
            let result = await new Promise((resolve, reject) => {
                db.collection("courses")
                    .get()
                    .then((snapShot) => {
                        let data = [];
                        snapShot.forEach((doc) => {
                            data.push(doc.data());
                        });
                        resolve(data);
                    })
                    .catch((error) => {
                        reject([]);
                    });
            });
            this.setState({ cources: result });
        })();
    }


    render() {
        const  cources  = getSpecialities()

        console.log("cources", cources);

        const cards = cources.map((card) => {
            return <SpecialityCard data={card} key={card.id} />;
        });

        return (
            <MainLayout title="Courses">
                <div className="container">
                    <div className="content wow zoomIn">
                        <h1 className="head-title head-centre pro-title">
                            <br />
                            <span>Projects</span>
                        </h1>

                    </div>
                </div>
                <div className="container">
                    <div className="row">{cards}</div>
                </div>
            </MainLayout>
        );


    }

}
const getSpecialities=()=>{
    const list=[
        {
            id:1,
            title:"SKILME",
            content:"A psychometric assessment e-tool to provide a comprehensive understanding of the strengths and weaknesses of an individual.",
            image:"/static/images/projects/skillme.jpg",
            link:'/skillme'
        },
        {
            id:2,
            title:"MASTERSMEET",
            content:"A one-stop solution for organizations and educational institutions to take their activities online without compromising the quality of work",
            image:"/static/images/projects/mnm.jpg",
            link:'/mastersmeet'
        },
        {
            id:3,
            title:"E-CLUB",
            content:"Education through entertainment offers a paradigm shift to the present-day educational system.",
            image:"/static/images/projects/eclub.jpg",
            link:'/eclub'
        }

    ]
    return list
}

export default Projects;
