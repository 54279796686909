import React, { Component } from "react";
import MainLayout from "../layout/MainLayout";
import GalleryGrid from "../components/Gallery/gallery-grid";
import clientCredentials from "../credentials/client";
import firebase from "firebase/app";
import "firebase/firestore";
import "isomorphic-unfetch";
import Loader from "../components/Loader/Loader";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: props.width,
      image: null,
      isLoading: true,
    };
  }
  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    if (window.innerWidth > 991) {
      this.setState({ width: window.innerWidth });
    } else {
      this.setState({ width: window.innerWidth });
    }
  }
  /**
   * Add event listener
   */
  componentDidMount() {
    (async () => {
      try {
        firebase.initializeApp(clientCredentials);
      } catch (error) {}

      let db = firebase.firestore();
      let result = await new Promise((resolve, reject) => {
        db.collection("gallery")
          .get()
          .then((querySnapshot) => {
            let images = [];
            querySnapshot.forEach(function (doc) {
              images.push(doc.data());
            });
            resolve(images);
          })
          .catch((error) => {
            reject([]);
          });
      });
      console.log('result:',result)
      this.setState({ image: result });
    })();

    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.setState({ isLoading: false });
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    let content = null;
    if (this.state.image != null && this.state.image.length > 10) {
      content = (
        <GalleryGrid width={this.state.width} image={this.state.image} />
      );
    } else {
      content = <Loader />;
    }
    return <MainLayout title="Gallery">{content}</MainLayout>;
  }
}

export default Gallery;
