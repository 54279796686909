import React, { Component } from "react";
import logo from "../../static/images/logo.svg";
import stopwatch from "../../static/images/stopwatch.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";

export class TestPageNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { isScrolled: false, min: 0, sec: 0 };
  }

  timer = "";

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);

    this.timer = setInterval(
      function () {
        if (this.state.sec < 59) {
          this.state.sec += 1;
        } else {
          this.state.sec = 0;
          this.state.min += 1;
        }
        this.forceUpdate();
      }.bind(this),
      1000
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
    clearInterval(this.timer);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled > 0) {
      this.setState({
        isScrolled: true,
      });
    } else {
      this.setState({
        isScrolled: false,
      });
    }
  };

  render() {
    return (
      <div className="sticky-top">
        <nav
          className={"bg-white " + (this.state.isScrolled ? "floatingNav" : "")}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <div className="navbar-brand wow zoomIn">
                  <img
                    className="logo float-left"
                    src={logo}
                    alt="Master n Masters"
                  />
                </div>
              </div>
              <div className="col-sm-4 my-auto timer">
                <img src={stopwatch} alt="" width="30" />
                <label>
                  {("0" + this.state.min).slice(-2)} <span>mins</span> :{" "}
                  {("0" + this.state.sec).slice(-2)} <span>sec</span>
                </label>
              </div>
              <div className="col-sm-4 my-auto end-test">
                <div className="end-button" onClick={this.props.endMethod}>
                  <label>
                    End Test
                    <span>
                      <FontAwesomeIcon icon={faFlagCheckered} />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default TestPageNavBar;
