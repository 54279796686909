import React from "react";
import { Link } from "react-router-dom";

const FooterMenu = () => {
  return (
    <div className="col-sm-2 quick-links d-none d-lg-block">
      {/*<h3>Menu</h3>*/}
      <ul className="footer-menu">
       {/* <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a href="/courses">Courses</a>
        </li>
        <li>
          <a href="/faculty">Faculty</a>
        </li>
        <li>
          <a href="/gallery">Gallery</a>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>*/}
      </ul>
    </div>
  );
};

export default FooterMenu;
