import React, { Component } from 'react';
import MainLayout from '../layout/MainLayout';
import fetch from 'isomorphic-unfetch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import SweetAlert from 'react-bootstrap-sweetalert';
class EventRegistration extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		/*this.addCountries=this.addCountries.bind(this)*/
		this.state = {
			alert: null
		};
	}

	handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);

		document.getElementById('sub_btn').innerText = 'Submit...';
		fetch('https://script.google.com/macros/s/AKfycbyWCtZFcTCWfyq1yCGWuoWvj2aTf0UsPZVbfgMkyJtfsUxA_dw/exec', {
			method: 'POST',
			body: data
		}).then((response) => {
			document.getElementById('sub_btn').innerText = 'Submit';
			//ToastsStore.success("Thanks for your registration on National Art Competition 2020... The registration link will be provided in this website shortly. www.masternmasters.com")
			this.successAlert();
			document.getElementById('reg_form').reset();
		});
	}

	///handle field change
	successAlert() {
		const getAlert = () => (
			<SweetAlert success title="Success!" onConfirm={() => this.hideAlert()}>
				Thanks for your registration on National Art Competition 2020... The registration link will be provided
				in this website shortly. www.masternmasters.com
			</SweetAlert>
		);
		console.log('done');
		this.setState({
			alert: getAlert()
		});
	}

	hideAlert() {
		console.log('Hiding alert...');
		this.setState({
			alert: null
		});
	}

	render() {
		return (
			<MainLayout title="Contact Us">
				{this.state.alert}

				<div className="container-fluid mb-5">
					<div className="row">
						<div className="art-section sec-1 pt-2 container-fluid ">
							<div className="col-md-8 col-12 pt-4">
								<h1 className="pt-2">National Art Competition 2020</h1>
								{/*<h6><b>Celebrate International Artist Day || 25<sup>th</sup> October 2020</b></h6>*/}
								<p className="text-justify pt-2">
									International Artists Day celebrate the incredible world of artists and their
									amazing creative works. Master n Masters organise national level art competition for
									all Professional Artists, School teachers, Students of Fine Arts Institutes,
									Schools, Colleges, Art faculty, anybody who has a propensity for drawing and
									painting. Any person with a minimum age of 16 years and above are eligible to
									participate in the competition. The competition intends to develop a platform of art
									loving passionate minds for their creative expression of thoughts.{' '}
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="art-section sec-2 container-fluid ">
							<div className="col-md-8 col-12 offset-md-4">
								<h5 className="">
									<b>Theme/Topic:</b>
								</h5>
								<p className="text-justify">
									Create Pictures with Hidden Concepts (Illusion or Perception)
								</p>
								<h5 className="pt-4">
									<b>Categories:</b>
								</h5>
								<p className="text-justify">
									Optical Illusion Art, Hidden Optical Illusion, Face Optical Illusion, Face Dot
									Optical Illusion, Face Tree Optical Illusion, Tricky Optical Illusion, Hidden Word
									Optical Illusion, Animal Optical Illusion, Cognitive Illusion, Perception Colour
									Illusion etc.
								</p>

								<h5 className="pt-4">
									<b>Who can Participate?</b>
								</h5>
								<p className="text-justify">
									Participants must be either Indian or Indian descendants with a minimum age of 16
									years <br />Professional Artists, School teachers, Students of Fine Arts Institutes,
									Schools, Colleges, Art faculty, or anybody who has a propensity for drawing and
									painting can participate
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="art-section sec-3  container-fluid ">
							<div className="col-md-8 col-12 ">
								{/*<h5 className='pt-4'><b>Medium of art</b></h5>*/}
								{/*<p className='text-justify'>Size of the artwork</p>*/}
								<h5 className="pt-4">
									<b>Size of the artwork</b>
								</h5>
								<p>Minimum 8’’ x 12’’ (A4) to Maximum 12’’ x 18’’ (inches) (A3)</p>
								<h5>
									<b>Medium and Surface</b>
								</h5>
								<p className="text-justify">
									You are free to choose your medium and surface. It could be pencils, coloured
									pencils, graphite pencils, charcoal, oil pastels, watercolours, acrylic colours, oil
									colours etc.{' '}
								</p>
								<h5>
									<b>Last date of submission extended to</b>
								</h5>
								<h4>
									<b>30th November 2020 (Monday)</b>
								</h4>
								<h5 className="pt-2">
									<b>Registration Procedure</b>
								</h5>
								<ul className="event-rules">
									<li>There is no participation fee to take part in the competition</li>
									<li>Each participant can submit a maximum of 3 entries.</li>
									<li>Register online through the link provided.</li>
									<li>
										Upload the scanned images of your art works through the link provided after
										registration.
									</li>
									<li>Specification of the scanned image: 300 px/in; Image Size : 4 MB - 8 MB</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="art-section sec-4 pt-5 container-fluid ">
							<div className="row text-center">
								<h2 className="text-center m-auto text-white">AWARDS</h2>
							</div>
							<div className="col-md-12 col-12 pt-4 text-center">
								<div className="row">
									<div className=" col-md-3 offset-md-2">
										<div className="container">
											<div className="bose-img">
												<img className="img-fluid" src="/static/images/art/BOSE.jpg" alt="" />
												<p className="text-white">
													<b>Bose Krishnamachari</b>
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-7">
										<div className="text-white">
											{/*<p className="text-white"><b>Bose Krishnamachari</b>, the internationally
                                                acclaimed painter and Artist Curator
                                                will announce the Awards in the Online Award Ceremony.</p>*/}
											<p className="lhight">
												<b>
													1<sup>st</sup> Prize :
												</b>{' '}
												Cash award of Rs.10001/-{' '}
											</p>
											<p className="lhight">
												<b>
													2<sup>nd</sup> Prize :
												</b>{' '}
												Cash award of Rs.5001/-{' '}
											</p>
											<p className="lhight">
												<b>
													3<sup>rd</sup> Prize :
												</b>{' '}
												Cash award of Rs.3001/-
											</p>
											<p className="lhight">
												<b>
													4<sup>th</sup> Prize(x2) :
												</b>{' '}
												Cash award of Rs.1001/-
											</p>
										</div>
									</div>
									<div className="col-md-12 text-white">
										<div className="container pt-3">
											<hr />
											<p>All winners will be provided Certificates. </p>
											<p>Short-listed participants will be provided online certificates</p>
											<h3 className="">
												<b>Declaration and Online Award Ceremony</b>
											</h3>
											<p>
												Last date of submission extended to <b> 30th November 2020.</b>{' '}
												<b>Bose Krishnamachari, </b>
												the internationally acclaimed painter and Artist Curator during the
												Online Award Ceremony Function conducted in Zoom platform.{' '}
											</p>
											<small className="mb-5">
												Shortlisted best pictures may be used in the psychometric assessment
												tool by Master n Masters.
											</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="art-section pt-5 container ">
							<h2 className="text-center">Registration</h2>
							<form action="" className="mt-4" id="reg_form" onSubmit={this.handleSubmit}>
								<div className="row">
									<div className="col-md-6">
										<label htmlFor="">Name</label>
										<input type="text" name="Name" id="" className="form-control" required />
									</div>
									<div className="col-md-6">
										<label htmlFor="">Mobile</label>

										<input
											type="tel"
											pattern=".{10,}"
											name="Mobile"
											id=""
											className="form-control"
											required
											title="Enter valid mobile number "
										/>
										<small>Please enter mobile number with country code</small>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-md-6">
										<label htmlFor="">Email</label>
										<input type="email" name="Email" id="" className="form-control" required />
									</div>
									<div className="col-md-6">
										<label htmlFor="">Address</label>
										<textarea
											name="Address"
											id=""
											placeholder=""
											className="form-control"
											required
										/>
									</div>
								</div>

								<div className="row mt-3">
									<div className="col-md-6 ">
										<div className="row">
											<div className="col-md-6">
												<div className="form-check">
													<input
														className="form-check-input"
														type="radio"
														name="Occupation"
														id="exampleRadios1"
														value="Student"
														checked
														required
													/>
													<label className="form-check-label" htmlFor="exampleRadios1">
														Student
													</label>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-check">
													<input
														className="form-check-input"
														type="radio"
														name="Occupation"
														id="exampleRadios2"
														value="Professional"
														required
													/>
													<label className="form-check-label" htmlFor="exampleRadios2">
														Professional
													</label>
												</div>
											</div>
											<div className="col-md-12 mt-3">
												<label htmlFor="">Gender</label>
												<div className="row">
													<div className="col-md-4">
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																name="Gender"
																id="exampleRadios1"
																value="Male"
																checked
																required
															/>
															<label
																className="form-check-label"
																htmlFor="exampleRadios1"
															>
																Male
															</label>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																name="Gender"
																id="exampleRadios2"
																value="Female"
																required
															/>
															<label
																className="form-check-label"
																htmlFor="exampleRadios2"
															>
																Female
															</label>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-check">
															<input
																className="form-check-input"
																type="radio"
																name="Gender"
																id="exampleRadios2"
																value="Other"
																required
															/>
															<label
																className="form-check-label"
																htmlFor="exampleRadios2"
															>
																Other
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<label htmlFor="">Date of Birth</label>
										<input type="date" name="DOB" id="" className="form-control" required />
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 text-center pt-4">
										<button className="btn btn-success submit-btn" id="sub_btn">
											Submit
										</button>
										<ToastsContainer
											position={ToastsContainerPosition.BOTTOM_CENTER}
											store={ToastsStore}
										/>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</MainLayout>
		);
	}
}

export default EventRegistration;
