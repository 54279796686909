import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { logEvent } from "../../utils/analytics";
import { Link } from "react-router-dom";

const HomeQuote = ({ quote, changed,para }) => {
  const content = quote
    .toUpperCase()
    .replace(/(\w+\s\w+?)$/, `<span>$1</span>`);

  return (
    <div className="col-md-6 col-sm-12 position-relative">
      <div className="position-absolute quote">
        <h1 className={changed ? "animated fadeIn" : ""}>
          {ReactHtmlParser(content)}
        </h1>
        <p > {ReactHtmlParser(para)}</p>


      </div>

    </div>
  );
};

export default HomeQuote;
