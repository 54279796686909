import React, {Component} from "react";
import MainLayout from "../layout/MainLayout";
import fetch from "isomorphic-unfetch";

class Eclub extends Component {


    render() {
        return (
            <MainLayout title="Contact Us">
                <div className="container mb-5">


                    <div className="content wow zoomIn">
                        <h1 className="head-title head-centre mt-5 mb-5">
                            <span>SKILME</span>
                        </h1>
                        <h5 className="sub-title head-centre mb-5">
                            <span>Assess Yourself to Bring Out The Best in You</span>
                        </h5>
                        <p className="text-center height-2 ">
                            SKILME is a psychometric assessment tool development project which envisions one's unique
                            abilities with the goal of helping them overcome their challenges. Technology has become an
                            integral part of our lives over the last 20 years, transforming almost every facet of human
                            communication and interaction. Scientific analysis of the behaviour, skills and attitudes of
                            an individual is a reliable method of assessing their capabilities and interests which are
                            likely to impact and influence learning in children and adolescents. Psychometric tests have
                            already been administered around the globe as an important decision-making process in
                            educational and career choices, educational and career placements, access to services,
                            therapeutic approach, special accommodations at school or university, etc.

                        </p>
                        <h3 className='sub-title text-center pt-2'><span>Research & Development</span></h3>
                        <p className='height-2 text-center mt-4 '>
                            The Research and Development division of Master n Masters is heading with a psychology
                            research project to develop a Psychometric Assessment E-tool, ‘SKILME’. The tool is being
                            developed by incorporating various regional parameters to tweak it for our native use. On
                            the basis of assessment, we provide professional guidance in tackling their learning
                            limitations and help nurture their skills and abilities through our child-centred
                            orientation programs.
                        </p>

                    </div>


                </div>
            </MainLayout>
        );
    }
}

export default Eclub;
