import React, { Component } from "react";
import Navbar from "../components/Navbar/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.css";
import "../static/scss/master.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import MaterialDatePicker from "../components/MaterialDatePicker/MaterialDatePicker";
import { initGA, logPageView, logEvent } from "../utils/analytics";

export class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      age: "",
      mobile: "",
      date: "",
      bookingdate: "",
      error: "",
      success: false,
      isLoading: false,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }

  onChangeHadler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: "",
    });
  };

  handleDateChange(date) {
    this.setState({
      date: date,
      bookingdate: this.formatDate(date),
    });
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  validateData() {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var mob = parseInt(this.state.mobile);
    if (this.state.name.trim() == "") {
      this.setState({ error: "Please input your name" });
    } else if (this.state.email.trim() == "") {
      this.setState({ error: "Please input your email" });
    } else if (!re.test(this.state.email.trim().toLowerCase())) {
      this.setState({ error: "Please input a valid email" });
    } else if (this.state.mobile.trim() == "") {
      this.setState({ error: "Please input your mobile number" });
    } else if (!/^\d+$/.test(this.state.mobile.trim())) {
      this.setState({ error: "Please input a valid mobile number" });
    } else if (mob > 9999999999 || mob < 5000000000) {
      this.setState({ error: "Please input a valid mobile number" });
    } else {
      this.setState({ isLoading: true });
      this.sendToServer();
    }
  }

  async sendToServer() {
    let response = await new Promise((resolve, reject) => {
      let res = fetch("/api/booking", {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "same-origin",
        body: JSON.stringify(this.state),
      })
        .then((r) => r.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject({ success: false });
        });
    });
    if (response.success) {
      logEvent("Booking", "Success");
      this.setState({
        success: response.success,
        isLoading: false,
      });
    } else {
      logEvent("Booking", "Error");
      this.setState({
        error: "Oopz.. Something went wrong..!!",
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <div
        className="full-screen book-opacity"
        style={{
          backgroundImage: "url(" + "../static/images/carousel/6.jpg" + ")",
        }}
      >
        <Navbar transparent={true} />
        <div className="container-fluid mar-top d-flex justify-content-center">
          <div className="container wow zoomIn pl-0 row">
            <div className="col-md-7 text-div">
              <h1 className="head-title mt-5">
                READY TO RISE?
                <br />
                <span>BOOK A COUNSELING SESSION WITH US.</span>
              </h1>
              <p className="text-centre my-5">
                Whether you are a student, a professional or anyone, we are here
                with our counseling session to make the change by assessing and
                monitoring your skills to find what fits you right. Our
                strategies and techniques help to nurture and build your most
                essential talents and mold you into a better person.
              </p>
            </div>
            <div className="col-md-5">
              <div className="card">
                {this.state.success ? (
                  <div className="text-center my-5">
                    <h1 className="smile-booking wow pulse">
                      <FontAwesomeIcon icon={faCheckCircle} />
                    </h1>
                    <h4 className="booking-detail">Done</h4>
                  </div>
                ) : (
                  <form className="m-5 wow slideInUp">
                    <div className="form-group">
                      <input
                        type="text"
                        required="required"
                        name="name"
                        onChange={this.onChangeHadler}
                      />
                      <label htmlFor="input" className="control-label">
                        Name
                      </label>
                      <i className="bar" />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        required="required"
                        name="email"
                        onChange={this.onChangeHadler}
                      />
                      <label htmlFor="input" className="control-label">
                        Email
                      </label>
                      <i className="bar" />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        required="required"
                        name="mobile"
                        onChange={this.onChangeHadler}
                      />
                      <label htmlFor="input" className="control-label">
                        Mobile Number
                      </label>
                      <i className="bar" />
                    </div>
                    <DatePicker
                      selected={this.state.date}
                      onChange={this.handleDateChange}
                      minDate={new Date()}
                      dateFormatCalendar={"MMM yyyy"}
                      customInput={<MaterialDatePicker />}
                    />

                    {this.state.error.length > 0 ? (
                      <div className="form-group">
                        <p className="error">{this.state.error}</p>
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      type="button"
                      className="book-btn"
                      onClick={() => {
                        this.validateData();
                      }}
                    >
                      <span>
                        {this.state.isLoading ? "Loading..." : "Book Now"}
                      </span>
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Booking;
