import React, { Component } from "react";
import TestLayout from "../layout/TestLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import QuestionItem from "../components/ChatItem/QuestionItem";
import AnswerItem from "../components/ChatItem/AnswerItem";
import TypingIndicator from "../components/ChatItem/TypingIndicator";
import { confirmAlert } from "react-confirm-alert";
import { logEvent } from "../utils/analytics";
import clientCredentials from "../credentials/client";
import firebase from "firebase/app";
import "firebase/firestore";
import "isomorphic-unfetch";

export class MasterTest extends Component {
  personalQstn = [
    {
      id: 1,
      qstn: "May i know your name ?",
      hasOpn: false,
    },
    {
      id: 2,
      qstn: "How old are you ?",
      hasOpn: false,
    },
    {
      id: 3,
      qstn: "Your Gender",
      hasOpn: true,
      options: {
        A: "Male",
        B: "Female",
      },
    },
    {
      id: 4,
      qstn: "What is your Email Id ?",
      hasOpn: false,
    },
    {
      id: 5,
      qstn: "What is Mobile number ?",
      hasOpn: false,
    },
    {
      id: 6,
      qstn: "Where are your from ?",
      hasOpn: false,
    },
    {
      id: 7,
      qstn: "What are you doing ?",
      hasOpn: true,
      options: {
        A: "I am Stduent",
        B: "I am professional",
      },
    },
  ];

  studntQstn = [
    {
      id: 1,
      qstn: "Which standerd are you studying ?",
      hasOpn: false,
    },
    {
      id: 2,
      qstn: "Your father's name ?",
      hasOpn: false,
    },
    {
      id: 3,
      qstn: "Your father job ?",
      hasOpn: false,
    },
    {
      id: 4,
      qstn: "Please select the integence test you want to do..",
      hasOpn: true,
      options: {
        "LEARNING STYLE  ASSESSMENT": "LSA-01",
        "MULTIPLE INTELLIGENCE": "MIA-01",
      },
    },
  ];

  proQstn = [
    {
      id: 1,
      qstn: "What is your Job ?",
      hasOpn: false,
    },
    {
      id: 2,
      qstn: "How long you been working ?",
      hasOpn: false,
    },
    {
      id: 3,
      qstn: "Please select the integence test you want to do..",
      hasOpn: true,
      options: {
        "LEARNING STYLE  ASSESSMENT": "LSA-01",
        "MULTIPLE INTELLIGENCE": "MIA-01",
      },
    },
  ];

  postTestMessages = [
    {
      id: 1,
      qstn: "Alright... You have success fully compleated our test",
      hasOpn: false,
    },
    {
      id: 2,
      qstn: "You are way more intelligent than you think you are...",
      hasOpn: false,
    },
    {
      id: 3,
      qstn:
        "A detailed report on your test will be sent your email in 24hrs...",
      hasOpn: false,
    },
    {
      id: 4,
      qstn:
        "In order to get take more intelligent test feel free to contact us...\nOr book counselling session",
      hasOpn: false,
    },
    {
      id: 5,
      qstn: "Thank your for your time with us... Have a good day...",
      hasOpn: false,
    },
  ];

  allQstns = [];
  step = 0;

  constructor(props) {
    super(props);
    this.state = {
      pos: 0,
      isTyping: true,
      typedAns: "",
      typeDisabled: "",
      result: [],
      testName: "",
    };
    this.allQstns = this.personalQstn;
    this.addAnswer = this.addAnswer.bind(this);
    this.endTest = this.endTest.bind(this);
    this.addEndMessages = this.addEndMessages.bind(this);
    this.sendResultToServer = this.sendResultToServer.bind(this);
  }

  content = [];

  // Scroll to bottom of chat screen after every questions
  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    this.scrollToBottom();
    // Typing indicator at fist run
    setTimeout(
      function () {
        this.setState({ isTyping: false });
        this.next();
      }.bind(this),
      800
    );
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  // Adding asnwer to array for rendering view
  addAnswer = (option, answer, pos, timetaken) => {
    this.state.result[pos] = {
      id: pos,
      qstn: this.allQstns[pos],
      option: option,
      answer: answer,
      time: timetaken,
    };
    this.content[pos * 2 + 1] = <AnswerItem answer={answer} option={option} />;
    this.forceUpdate();
    if (pos === this.state.pos) {
      this.state.pos += 1;
      this.next();
    }
  };

  // Adding Next question on answering
  next = () => {
    if (
      this.allQstns[this.state.pos] &&
      this.state.pos < this.allQstns.length
    ) {
      this.setState({ isTyping: true });
      setTimeout(
        function () {
          this.content.push(
            <QuestionItem
              key={this.state.pos}
              pos={this.state.pos}
              data={this.allQstns[this.state.pos]}
              onNext={this.addAnswer}
            />
          );
          this.setState({ isTyping: false });
        }.bind(this),
        600
      );
    } else {
      if (this.step == 0) {
        //Adding qstns deppending on what they are doing
        if (this.state.result.pop().answer == "I am Stduent") {
          this.allQstns = this.allQstns.concat(this.studntQstn);
        } else {
          this.allQstns = this.allQstns.concat(this.proQstn);
        }
        this.step++;
        this.next();
      } else if (this.step == 1) {
        this.getQstsn(this.state.result.pop().answer.trim());
      } else {
        this.addEndMessages(0);
        this.sendResultToServer();
      }
    }
  };

  async getQstsn(testName) {
    this.setState({ testName: testName, isTyping: true });
    try {
      firebase.initializeApp(clientCredentials);
    } catch (error) {}

    let db = firebase.firestore();
    let result = await new Promise((resolve, reject) => {
      db.collection("tests")
        .doc(testName)
        .get()
        .then((snapShot) => {
          if (snapShot.data()) {
            resolve(snapShot.data().questions);
          } else {
            reject([]);
          }
        })
        .catch((error) => {
          console.log(error);
          reject([]);
        });
    });
    this.allQstns = this.allQstns.concat(result);
    this.step++;
    this.next();
  }

  async sendResultToServer() {
    console.log(this.state.result);
    let name = this.state.result[0].answer;
    let response = await new Promise((resolve, reject) => {
      let res = fetch("/api/result-upload", {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "same-origin",
        body: JSON.stringify({
          name: name,
          time: Date.now(),
          result: this.state.result,
          testName: this.state.testName,
        }),
      })
        .then((r) => r.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject({ success: false });
        });
    });
    if (response.success) {
      logEvent("Test", "Success");
      console.log("Success");
    } else {
      logEvent("Test", "Error");
      console.log("Error");
    }
  }

  // Adding questions after compeleting the test
  addEndMessages = (pos) => {
    this.setState({ isTyping: true });
    setTimeout(
      function () {
        this.state.pos++;
        this.setState({ isTyping: true });
        if (pos < this.postTestMessages.length) {
          this.content.push(
            <QuestionItem
              pos={this.state.pos}
              data={this.postTestMessages[pos]}
              onNext={this.addAnswer}
            />
          );
          this.setState({ isTyping: false });
          pos++;
          this.addEndMessages(pos);
        } else {
          this.setState({ isTyping: false });
        }
      }.bind(this),
      2000
    );
  };

  // Adding user typed asnwer to array for rendering view
  sendTypedAnswer = (event) => {
    event.preventDefault();
    this.state.result[this.state.pos] = {
      id: this.state.pos,
      qstn: this.allQstns[this.state.pos],
      answer: this.state.typedAns,
      time: 0,
    };
    this.content[this.state.pos * 2 + 1] = (
      <AnswerItem answer={this.state.typedAns} option={null} />
    );
    this.state.pos += 1;
    this.forceUpdate();
    this.next();
    this.answerField.value = "";
  };

  ///handle field change
  onChangeHadler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  // End test button action
  endTest = () => {
    confirmAlert({
      title: "Confirm to End",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.state = {};
            this.props.history.push("/");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  render() {
    if (this.allQstns[this.state.pos]) {
      this.state.typeDisabled = this.allQstns[this.state.pos].hasOpn;
    } else {
      this.state.typeDisabled = true;
    }

    return (
      <TestLayout endMethod={this.endTest}>
        <div className="container bg-white chat-screen">
          <div className="test-name">Test Name</div>
          <div className="chat-area container-fluid">
            {this.content.map((jsx) => {
              return jsx;
            })}
            {this.state.isTyping ? <TypingIndicator /> : null}
            <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                this.messagesEnd = el;
              }}
            />
          </div>
          <form
            className="input-area container-fluid"
            onSubmit={this.sendTypedAnswer}
          >
            <input
              type="text"
              name="typedAns"
              required={true}
              onChange={this.onChangeHadler}
              placeholder={
                this.state.typeDisabled
                  ? "Select an option..."
                  : "Type something..."
              }
              disabled={this.state.typeDisabled}
              ref={(el) => (this.answerField = el)}
            />
            <div className="send-button">
              <button
                type="submit"
                title={this.state.typeDisabled ? "Select an option" : ""}
                disabled={this.state.typeDisabled}
              >
                Send
                <span>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </span>
              </button>
            </div>
          </form>
        </div>
      </TestLayout>
    );
  }
}

export default MasterTest;
