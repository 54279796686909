import React, { Component } from "react";
import HomeQuote from "../components/HomeComponets/HomeQuote";
import HomeCarouselPreview from "../components/HomeComponets/HomeCarouselPreview";
import Navbar from "../components/Navbar/Navbar";
import HomeSocialLinks from "../components/HomeComponets/HomeSocialLinks";
import HomeCaroselControll from "../components/HomeComponets/HomeCaroselControll";
import "animate.css/animate.css";
import { initGA, logPageView } from "../utils/analytics";
import WOW from "wowjs";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: 0,
      changed: false,
      width: props.width,
      isLoading: true,
    };
  }

  content = [
    {
      id: 1,
      quote: "Know Your Strengths and Nurture Them Now",
      para:"Analyse and develop your skills with SKILME - the smart and effective psychometric assessment tool. ",
      image: "/static/images/carousel/1.jpg",
    },
    {
      id: 2,
      quote: "Online Meetings and virtual Classrooms Redefined.",
      para:"Unveiling an upgraded and flawless aspect of educational and organisational meetings.",
      image: "/static/images/carousel/2.jpg",
    },
    {
      id: 3,
      quote: "Complementing the Existing Educational Platforms",
      para:"Add quality and value to your time by joining the future of education - E-CLUB",
      image: "/static/images/carousel/3.jpg",
    },
    {
      id: 4,
      quote: "Research and Development",
      para:"We run deep research on every subject we proffer, and we have an efficient team of experts to back us up.",
      image: "/static/images/carousel/4.jpg",
    },
    {
      id: 5,
      quote: "Our Courses and Programs",
      para:"A diverse array of certified courses and programs aimed at skill and talent development\n",
      image: "/static/images/carousel/5.jpg",
    },
    /*{
      id: 6,
      quote:
        "Get involved, evolve a powerful vision and organizational competence",
      image: "/static/images/carousel/6.jpg",
    },*/
  ];

  updateDimensions() {
    if (typeof window !== "undefined") {
      this.setState({ width: window.innerWidth });
    }
  }

  componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
    if (typeof window !== "undefined") {
      window.WOW = WOW;
      window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    new WOW.WOW().init();
    this.updateDimensions();
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateDimensions.bind(this));
    }
  }

  render() {
    const onSlideChanged = (e) => {
      this.setState({
        pos: e.item,
        changed: true,
      });
      this.timer = setTimeout((_) => {
        this.setState({ changed: false }); // fade back in
      }, 1000);
    };

    return (
      <div
        className="full-screen"
        style={{
          backgroundImage: "url(" + this.content[this.state.pos].image + ")",
        }}
      >
        <Navbar transparent={true} />
        <div className="home-quote">

        </div>
        <div className="container-fluid wow zoomIn pl-0">
          <div className="row">
            <div className="col-md-6">
              <div className="home-about">
                <h2>ENSURE YOUR SUCCESS</h2>
                <p>Smart platforms for your academic and professional excellence</p>

                <div className="home-buttons">
                  {/* <div className="test-button">

                    <a href='/competition'>
                      Art Competition 2020
                    </a>
                  </div>
                  <div className="test-button ">

                    <a href='/upload'>Upload Your Contest</a>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.width > 769 ? (
              <HomeQuote
                quote={this.content[this.state.pos].quote}
                changed={this.state.changed}
                para={this.content[this.state.pos].para}

              />
            ) : null}

            <HomeCarouselPreview
              image={this.content[this.state.pos].image}
              pos={this.state.pos + 1}
              size={this.content.length}
              changed={this.state.changed}
            />

            {this.state.width > 769 ? null : (
              <HomeQuote
                quote={this.content[this.state.pos].quote}
                changed={this.state.changed}
              />
            )}
          </div>
        </div>
        <div className="container-fluid align-bottom home-slide-controls bottom-margin wow slideInUp">
          <div className="row">
            {this.state.width > 769 ? <HomeSocialLinks /> : null}
            <HomeCaroselControll
              content={this.content}
              onChanged={onSlideChanged}
            />
            {this.state.width > 769 ? null : <HomeSocialLinks />}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
