import React, { Component } from "react";
import master from "../../static/images/master.svg";
import OptionItem from "./OptionItem";

export class QuestionItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: "",
			sec: 0
		};
		this.updateSelected = this.updateSelected.bind(this);
	}

	refreshIntervalId = null;

	updateSelected = (option, answer, pos) => {
		this.setState({
			selected: pos
		});
		this.props.onNext(option, answer, pos, this.state.sec);
		clearInterval(this.refreshIntervalId);
	};
	componentWillUnmount() {
		clearInterval(this.refreshIntervalId);
	}

	render() {
		const { data, onNext, pos } = this.props;
		let option = [];

		if (data.hasOpn && data.options) {
			for (var key in data.options) {
				if (data.options.hasOwnProperty(key)) {
					const selected = this.state.selected == key;
					option.push(
						<OptionItem
							key={key}
							option={key}
							answer={data.options[key]}
							pos={pos}
							selectUpdate={this.updateSelected}
							selected={selected}
						/>
					);

					this.refreshIntervalId = setInterval(
						function() {
							this.state.sec += 1;
						}.bind(this),
						1000
					);
				}
			}
		}

		return (
			<div className="chat-item">
				<div className="chat-avatar">
					<img src={master} alt="Master" />
				</div>
				<div className="chat-content">
					<div className="question">{data.qstn}</div>
					{option.map(jsx => {
						return jsx;
					})}
				</div>
			</div>
		);
	}
}

export default QuestionItem;
