import React, { Component } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { initGA, logPageView } from "../utils/analytics";
import "animate.css/animate.css";
import "../static/scss/master.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import WOW from "wowjs";

export class MainLayout extends Component {
  componentDidMount() {
    //---------*/Analytics-----------------
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
    //---------Analytics/*-----------------
    if (typeof window !== "undefined") {
      window.WOW = WOW;
    }
    new WOW.WOW().init();
  }

  render() {
    // const title = this.props.title + " | Master N Masters";
    return (
      <React.Fragment>
        {/* <Head>
          <title>{title}</title>
        </Head> */}
        <Navbar />
        {this.props.children}
        <Footer />
      </React.Fragment>
    );
  }
}

export default MainLayout;
