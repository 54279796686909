import React from "react";
import { Link } from "react-router-dom";

const CourceCard = ({ data }) => {
  let content;
  if (Array.isArray(data.levels)) {
    const list = data.levels.map((level) => <li key={level}>{level}</li>);

    content = (
      <>
        <p className="level-head">Levels</p>
        <ul className="levels">{list}</ul>
      </>
    );
  } else {
    content = <p className="level-alt">{data.levels}</p>;
  }

  function PostLink({ data, children }) {
    return (
      <Link
        to={{
          pathname: `/coursedetail/${data.id}`,
          courseData: JSON.stringify(data),
        }}
      >
        {children}
      </Link>
    );
  }

  return (
    <div className="col-sm-4 mt-5 wow fadeInUp" key={data.id}>
      <div className="cource-card">
        <div className="cource-img">
          <img src={data.image} alt="Heading" />
        </div>
        <div className="details text-center">
          <h4>{data.courceName.toUpperCase()}</h4>
          {data.catogory == "" ? "" : "( " + data.catogory + " )"}
          <div className="vertical-center">{content}</div>
          <PostLink data={data}>
            <a className="read-more">Read More</a>
          </PostLink>
        </div>
      </div>
    </div>
  );
};

export default CourceCard;
