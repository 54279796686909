import React, { Component } from "react";
import { Link } from "react-router-dom";
import fetch from "isomorphic-unfetch";

export class FooterNewsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      success: false,
      isLoading: false,
    };
    this.subscribe = this.subscribe.bind(this);
  }
  ///handle field change
  onChangeHadler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: "",
    });
  };

  subscribe(event) {
    event.preventDefault();
    const email_id = this.state.email.trim();
    if (!email_id == "") {
      this.setState({ isLoading: true });
      this.sentMailtoServer(email_id);
    }
  }

  async sentMailtoServer(email) {
    let response = await new Promise((resolve, reject) => {
      let res = fetch("/api/news-letter", {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "same-origin",
        body: JSON.stringify({ mail: email }),
      })
        .then((r) => r.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject({ success: false });
        });
    });
    if (response.success) {
      this.setState({
        success: response.success,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return this.state.success ? (
      <div className="col-sm-6 news-letter">
        <div className="form-group">
          <p className="success">Thank you for your subscription...</p>
        </div>
        <p className="copyright">
          &copy; 2019 Master n Masters
          <br />
          Powered by {""}
          <a
            href="https://stackatech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            StackATech
          </a>
        </p>
      </div>
    ) : (
      <div className="col-sm-6 news-letter">
        <h5>Subscribe To Our Newsletter</h5>
        <form onSubmit={this.subscribe}>
          <input
            type="email"
            name="email"
            placeholder="Please Enter Your Email"
            onChange={this.onChangeHadler}
            required
          />
          <button type="submit">
            {this.state.isLoading ? "Loading..." : "Subscribe"}
          </button>
        </form>
        <p className="copyright">
          &copy; 2019 Master n Masters
          <br />
          Powered by {""}
          <a
            href="https://stackatech.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            StackATech
          </a>
        </p>
      </div>
    );
  }
}

export default FooterNewsletter;
