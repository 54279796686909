import React, { Component } from "react";

export class MaterialDatePicker extends Component {
  render() {
    return (
      <div className="form-group" onClick={this.props.onClick}>
        <input
          type="text"
          required="required"
          name="date"
          onChange
          value={this.props.value}
        />
        <label htmlFor="input" className="control-label">
          Date
        </label>
        <i className="bar" />
      </div>
    );
  }
}

export default MaterialDatePicker;
